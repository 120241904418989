import axios from 'axios'
import {url} from '../utils/baseUrl.js'
export function fileitem(obj){
    // console.log(obj)
    return new Promise((resolve,reject)=>{
        axios({
            url:url + "/h5/doubleElevenShoppingList/create",
            method:"POST",
            data:obj
        }).then((res)=>{
            // console.log(res)
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}
export function queryitem(id){
    // console.log(id)
    return new Promise((resolve,reject)=>{
        axios({
            url:url + "/h5/doubleElevenShoppingList/getInfo",
            method:"POST",
            data:id
        }).then((res)=>{
            // console.log(res)
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}
// 敏感词过滤
export function sensitivewords(str){
    // console.log(id)
    return new Promise((resolve,reject)=>{
        axios({
            url:"https://api.xiaoxxx.com/sensitive",
            method:"get",
            params:{
                word:str
            }
        }).then((res)=>{
            // console.log(res)
            resolve(res)
        }).catch((err)=>{
            reject(err)
        })
    })
}